<template>
    <div>
        <page-title :heading="$t('erp.lang_wareOut')" :icon=icon
                    :subheading="$t('erp.lang_wareOut')"
                    show-previous-button></page-title>
        <div class="app-main__inner">
            <good-issue-edit-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import GoodIssueEditComponent from "../../../../components/erp/storage/goodissue/GoodIssueEditComponent";

    export default {
        components: {
            PageTitle,
            GoodIssueEditComponent
        },
        props: ["id"],
        data: () => ({
            icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
        })
    }
</script>